import { DocScripts } from "../components/widgets/codeBlock";

type LANGUAGES = 'JavaScript' | 'Python' | 'NodeJS' | 'JSON';

type ScriptEntries = 'registro';

export const clienteScripts = {
    registro: {
        requests: [{
            language: 'JSON',
            subtitle: 'Cuerpo del request',
            subsubtitle: '(application/json)',
            code: `
{
    "rfc": "IVD920810GU2",
    "regimenFiscal": "601",
    "nombre": "INNOVACION VALOR Y DESARROLLO SA",
    "lugarExpedicion": "61957",
    "isProduction":false 
}`
        }],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
{
    "rfc": "IVD920810GU2",
    "regimenFiscal": "601",
    "nombre": "INNOVACION VALOR Y DESARROLLO SA",
    "lugarExpedicion": "61957",
    "isProduction": false,
    "authToken": "...",
    "masterToken": "..."
}`
        }
    } as DocScripts,
    certificados: {
        requests: [{
            language: 'HTML',
            subtitle: 'Subir archivo con HTML solo',
            subsubtitle: 'multipart/form-data',
            code: `
<form action="https://api.fiscalpop.com/api/v1/upload/cer/:authToken" method="post" enctype="multipart/form-data">
    <label for="upload">Certificado:</label>
    <input type="file" accept=".cer" id="upload" name="upload">
    <input type="submit" value="Submit">
</form>`
        },{
            language: 'JavaScript',
            subtitle: 'Subir archivo desde Web',
            subsubtitle: 'multipart/form-data',
            precode: {
                language:'HTML',
                code: `
<form onsubmit="makeFormData(event);">
    <label for="upload">Certificado:</label>
    <input type="file" accept=".cer" id="upload" name="upload">
    <input type="submit" value="Submit">
</form>`
            },
            code: `
function makeFormData(event) {
    event.preventDefault();
    const cerFile = document.getElementById("upload").files[0];
    // Asumiento que rfc y authToken están definidas en otro lado
    uploadCertificate(cerFile, rfc, authToken);
}

function uploadCertificate(cerFile, rfc, authToken) {
    const compatibleFileName = new File([cerFile], rfc+'.cer', {type: 'application/pkix-cert'})
    const formData = new FormData();
    formData.append('upload', compatibleFileName);
    return fetch('https://api.fiscalpop.com/api/v1/upload/cer/'+authToken, {
        method: 'POST',
        credentials: 'omit',
        body: formData
    }).then(response => {
        if (response.ok) {
            return response.json()
        } else {
            throw response.text();
        }
    }).then(respuesta => {
        console.log('Respuesta:', respuesta);
        // Ejecuta tu propia lógica aquí 
    }).catch(errProm => {
        errProm.then(err => {
            // El error desde el servidor se recibe como "Promise" 
            console.log('Cert submit err: ', err);
            // Ejecuta tu propia lógica de error aquí 
        })
        .catch(() => null);
    });
}`
        }],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
{
    keyPem: '...',
    keyModulus: '...',
    cer: '...',
    cerNumber: '...',
    cerModulus: '...',
    notBefore: 2020-08-27T06:53:19.174Z,
    notAfter: 2024-08-27T06:53:19.174Z,
    authToken: '...',
    masterToken: '...',
    modulusMatch: true,
}`
        }
    } as DocScripts,
    llaves: {
        requests: [{
            language: 'HTML',
            subtitle: 'Subir archivo con HTML solo',
            subsubtitle: 'multipart/form-data',
            code: `
<form action="https://api.fiscalpop.com/api/v1/upload/key/:authToken" method="post" enctype="multipart/form-data">
    <label for="password">Contraseña:</label>
    <input type="text" id="password" name="password">
    <label for="upload">Llave KEY:</label>
    <input type="file" accept=".key" id="upload" name="upload">
    <input type="submit" value="Submit">
</form>`
        }, {
            language: 'JavaScript',
            subtitle: 'Subir archivo con JavaScript',
            subsubtitle: 'multipart/form-data',
            precode: {
                language: 'HTML',
                code: `
<form onsubmit="makeFormData(event);">
    <label for="password">Contraseña:</label>
    <input type="text" id="password" name="password">
    <label for="upload">Llave KEY:</label>
    <input type="file" accept=".key" id="upload" name="upload">
    <input type="submit" value="Submit">
</form>`
            },
            code: `
function makeFormData(event) {
    event.preventDefault();
    const password = document.getElementById("password").value;
    const keyFile = document.getElementById("upload").files[0];
    // Asumiento que rfc y authToken están definidas en otro lado
    uploadKey(keyFile, rfc, authToken, password);
}

function uploadKey(keyFile, rfc, authToken, password) {
    const compatibleFileName = new File([keyFile], rfc+'.key', {type: 'application/pkcs8'})
    const formData = new FormData();
    formData.append('upload', compatibleFileName);
    formData.append('password', password);
    return fetch('https://api.fiscalpop.com/api/v1/upload/key/'+authToken, {
        method: 'POST',
        credentials: 'omit',
        body: formData
    }).then(response => {
        if (response.ok) {
            return response.json()
        } else {
            throw response.text();
        }
    }).then(respuesta => {
        console.log('respuesta: ', respuesta);        
        // Ejecuta tu propia lógica aquí 
    }).catch(errProm => {
        errProm.then(err => {
            // El error desde el servidor se recibe como "Promise" 
            console.log('Key submit err: ', err);
            // Ejecuta tu propia lógica de error aquí 
        })
        .catch(() => null);
    });
}`
        }],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
{
    keyPem: '...',
    keyModulus: '...',
    cer: '...',
    cerNumber: '...',
    cerModulus: '...',
    notBefore: 2020-08-27T06:53:19.174Z,
    notAfter: 2024-08-27T06:53:19.174Z,
    authToken: '...',
    masterToken: '...',
    modulusMatch: true,
}`
        }
    } as DocScripts,
    editar: {
        requests: [{
            language: 'JSON',
            subtitle: 'Cuerpo del request',
            subsubtitle: '(application/json)',
            code: `
{
    "nombre": "HORACIO LLANOS",
    "lugarExpedicion": "06700"
    "regimenFiscal": "625"
}`
        }],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
{
    "rfc": "ACO560518KW7",
    "regimenFiscal": "625",
    "nombre": "HORACIO LLANOS",
    "isProduction": false,
    "authToken": "...",
    "masterToken": "...",
    "lugarExpedicion": "06700"
}`
        }
    } as DocScripts,
    borrar: {
        requests: [{
            language: 'JSON',
            subtitle: 'Cuerpo del request',
            subsubtitle: '(application/json)',
            code: `
{
    "authToken": "..."
}`
        }],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
{
    "removed": true,
    "authToken": "..."
}`
        }
    } as DocScripts,
    buscar: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
// Find all clients 
[{
    "rfc": "ACO560518KW7",
    "regimenFiscal": "625",
    "nombre": "HORACIO LLANOS",
    "isProduction": false,
    "authToken": "...",
    "masterToken": "...",
    "lugarExpedicion": "06700"
},{...}]

// Find client by AuthToken 
{
    "rfc": "ACO560518KW7",
    "regimenFiscal": "625",
    "nombre": "HORACIO LLANOS",
    "isProduction": false,
    "authToken": "...",
    "lugarExpedicion": "06700"
}`
        }
    } as DocScripts,
    billing: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "masterToken": "659ddcb1-799b-4149-86e8-1b8b54bb56ec",
        "month": 7,
        "year": 2018,
        "paid": false,
        "type": "service",
        "createdAt": "2018-08-22T05:52:12.565Z",
        "owed": 0,
        "owedTax": 0,
        "paidDate": null,
        "stamped": 0,
        "canceled": 0,
        "stampedTest": 3,
        "distribution": [
            {
                "authToken": "25519f51-8710-4c37-a16e-38da5abcf314",
                "month": 7,
                "rfc": "ACO560518KW7",
                "year": 2018,
                "canceled": 0,
                "stamped": 3
            }
        ]
    }
]`
        }
    } as DocScripts,
}
